import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Typography from "@material-ui/core/Typography"
import { Container } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  backgroundImage: {
    minHeight: 540,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    backgroundPositionY: "center",
  },
  textBackground: {
    width: "100%",
    minHeight: 540,
    height: "100%",
    backgroundImage:
      "linear-gradient(to bottom, rgba(255,255,255,0), rgba(32, 33, 34, 1))",
  },
  textArticle: {
    marginTop: 150,
    marginBottom: 100,
    maxWidth: 640,
    padding: 30,
  },
  button: {
    marginTop: 25,
  },
}))

const images = {
  TRACK: "track",
  VAN: "van",
  DOG: "dog",
  INDEX: "index",
  CONTACT: "contact",
}

const query = graphql`
  query {
    index: file(relativePath: { eq: "indexImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    track: file(relativePath: { eq: "prevozRobe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dog: file(relativePath: { eq: "selidbe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contact: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logistics: file(relativePath: { eq: "logistics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    warehouse: file(relativePath: { eq: "warehouse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const BackgroundImageSection = ({ image, heading, text, buttonText, id }) => {
  const classes = useStyles()

  return (
    <StaticQuery
      query={query}
      render={data => {
        // Set ImageData.
        const imageData = data[image].childImageSharp.fluid

        return (
          <BackgroundImage
            className={classes.backgroundImage}
            Tag="section"
            fluid={imageData}
          >
            <div id={id} className={classes.textBackground}>
              <Container className={classes.textArticle}>
                <Typography variant="h3" gutterBottom>
                  {heading}
                </Typography>
                {text}
                {buttonText.length > 0 && (
                  <Button
                    className={classes.button}
                    variant="outlined"
                    href="tel:0616618497"
                  >
                    {buttonText}
                  </Button>
                )}
              </Container>
            </div>
          </BackgroundImage>
        )
      }}
    />
  )
}

BackgroundImageSection.propTypes = {
  image: PropTypes.oneOf(Object.values(images)),
}

BackgroundImageSection.defaultProps = {
  image: images.INDEX,
}

export default BackgroundImageSection
